<template>
  <div class="home">
    <van-nav-bar title="资料" left-arrow id="reset" left-text="返回" @click-left="onClickLeft"/>
    <van-cell title="头像">
      <!-- 使用 right-icon 插槽来自定义右侧图标 -->
      <template #right-icon>
        <img :src="busiesslist.thumb_path" alt="" class="img">
      </template>
    </van-cell>
	
	<van-cell title="余额(元)" :value="busiesslist.yufufei"/>
    <van-cell title="部门" :value="busiesslist.department_name"/>
    <van-cell title="岗位" :value="busiesslist.role_name" />
    <!-- <van-cell title="性别" is-link value="男" /> -->
    <van-cell title="电话" :value="busiesslist.telephone" />
    <van-cell title="邮箱" :value="busiesslist.email"/>
    <van-cell title="地址" :value="busiesslist.address" />

  </div>
</template>


<script>
	import moment from 'moment';

export default {
  data() {
    return {
      active: 0,
	  list:{},
	  busiesslist:{},
	  
    };
  },created () {
 			// console.log(this.$route.query.id);
    this.getbusiness()
    // this.getHistoryData()
  },
methods:{
	
		tellTo(contacts_telephone)
		{
	 		console.log(contacts_telephone);
			this.$router.push({
			  path: `/bohao?id=`+contacts_telephone,
			})
			
		},
	routeTo(customer_id)
	{
 		console.log(customer_id);
		this.$router.push({
		  path: `/kehuxiangqing?id=`+customer_id,
		})
		
	},
	dateFormat(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD");
    },
 
    onClickLeft() {
    this.$router.go(-1);//返回上一层
    },
 getbusiness()
 {
	 
	 	let param1 = new URLSearchParams();
	 	
	 	// param1.append("module", 'customer');
	 	param1.append("id", this.$route.query.id);
	 	 param1.append("token", this.$store.getters. getDemoValue);
	 	
	 	this.axios.post('/vue.php?m=user&a=view',param1).then(res1 => {
	 		
	 		if (res1.data.status === -1) {
	 			setTimeout(()=>{
	 				this.$router.push({
	 				  path:"/"
	 				})
	 			},1000)
	 		}
	 		if (res1.data.status === 1) {
	 			
	 			// console.log(11111);
				this.busiesslist = res1.data.data;//后台获取的数据

	 			// this.busiesslist = res1.data.list;
	 			console.log(this.busiesslist);
	 		}
	 		// console.log(res1);
	 	}).catch(function (error){
	 		// Message.info('hello');
	 		console.log(error);
	 	});
	 	
 },
 
 getHistoryData(){
 	let param = new URLSearchParams();
 	
 	param.append("id", this.$route.query.id);
 	 param.append("token", this.$store.getters. getDemoValue);
 	
 	this.axios.post('/vue.php?m=customer&a=dynamic',param).then(res => {
 		if (res.data.status === -1) {
 			setTimeout(()=>{
 				this.$router.push({
 				  path:"/"
 				})
 			},1000)
 		}
 		if (res.data.status === 1) {
 			
			this.list = res.data.data;
 			console.log(this.list);
 		}
 		console.log(res);
 		console.log(error);
 	});
 	
   // this.$router.push({
   //   path:"/crm"
   // })
 }
 
},

};
</script>
<style lang="scss" scoped>
#reset ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.van-nav-bar {
  background: #1288f4;
}
#reset ::v-deep .van-icon.van-icon-arrow-left.van-nav-bar__arrow {
  color: white !important;
}
.img{
    width: 45px;
height: 45px;
}
.left{
    width: 7px;
height: 11px;
}
</style>